<template>
  <v-dialog v-model="dialog" persistent width="1500">
    <common-card>
      <CommondLoading
        v-if="loadingModal"
        :loadingImage="dataLoading.inter"
        :show="loadingModal"
        loadingText="Cargando info"
      />
      <v-card-text class="pa-4 black--text" v-else>
        <div
          class="pt-5 pb-5 top-text"
          style="width: 100%; height: 100%; justify-content: center; text-align: center;"
        >
          {{ idCampaing ? "Edicion" : "Creación" }} de campaña
        </div>
        <div class="pt-5 pb-5">
          <commond-form
            :request="request"
            :route="route"
            :action="'POST'"
            :run="validate"
            @cancel="validate = false"
            @success="$emit('successCampaing')"
            @afterError="afterError"
          >
            <template v-slot:data>
              <v-row>
                <v-col cols="12" m="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    label="Negocio (Reglas de marcación)"
                    outlined
                    :items="['B2C', 'B2B2C', 'LIFO']"
                    v-model="request.negocio"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3">
                  <v-text-field
                    label="Medio"
                    outlined
                    v-model="request.medio"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-text-field
                    label="Producto"
                    outlined
                    v-model="request.producto"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    label="Canal"
                    outlined
                    :items="canales"
                    item-text="canal"
                    item-value="canal"
                    v-model="request.canal"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3">
                  <v-autocomplete
                    label="Ramo"
                    outlined
                    :items="ramos"
                    item-text="ramo"
                    item-value="ramo"
                    v-model="request.ramo"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-text-field
                    label="Servicio Embebido"
                    outlined
                    v-model="request.servicio_embebido"
                    primary
                    color="#00a7e4"
                    type="text"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-text-field
                    label="Intentos marcación"
                    outlined
                    v-model="request.intentos"
                    primary
                    color="#00a7e4"
                    type="number"
                    counter
                    maxlength="2"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-text-field
                    label="Time hold"
                    outlined
                    v-model="request.time_hold"
                    primary
                    color="#00a7e4"
                    type="number"
                    counter
                    maxlength="2"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <hr />
              <v-row class="pt-5">
                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Servicio chat"
                        outlined
                        v-model="request.servicio_chat"
                        primary
                        color="#00a7e4"
                        type="text"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span
                      >Este campo es solo para los medios que tendran
                      configuracion para envio de chats</span
                    >
                  </v-tooltip>
                </v-col>

                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Intentos chat"
                        outlined
                        v-model="request.intentos_chat"
                        primary
                        color="#00a7e4"
                        type="number"
                        counter
                        maxlength="2"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span
                      >Este campo es solo para los medios que tendran
                      configuracion para envio de chats</span
                    >
                  </v-tooltip>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Template chat"
                        outlined
                        v-model="request.id_templete_chat"
                        primary
                        color="#00a7e4"
                        type="text"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span
                      >Este campo es solo para los medios que tendran
                      configuracion para envio de chats</span
                    >
                  </v-tooltip>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Contacto chat"
                        outlined
                        v-model="request.contacto_chat"
                        primary
                        color="#00a7e4"
                        type="number"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span
                      >Este campo es solo para los medios que tendran
                      configuracion para envio de chats</span
                    >
                  </v-tooltip>
                </v-col>
                <v-col cols="12" m="12" md="6" lg="3" xl="3 ">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="App ID"
                        outlined
                        v-model="request.app_id"
                        primary
                        color="#00a7e4"
                        type="text"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span
                      >Este campo es solo para los medios que tendran
                      configuracion para envio de chats</span
                    >
                  </v-tooltip>
                </v-col>
              </v-row>
            </template>
          </commond-form>
        </div>
        <div>
          <v-row style="text-align: end;">
            <v-col cols="12">
              <v-btn
                text
                color="#00a7e4"
                outlined
                dark
                style="border-radius: 15px;"
                @click="$emit('closeModal')"
              >
                Cancelar</v-btn
              >
              <v-btn class="common-botton" dark @click="run = true"
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </common-card>
  </v-dialog>
</template>

<script>
import CommonCard from "../CommonCard.vue";
import { getCanalList } from "@/services/canal/canal.service.js";
import { getRamoList } from "@/services/ramo/ramo.service.js";
import { trimJsonMethod } from "@/helpers/trim-json.js";
import { getCampaingDetails } from "@/services/campaings/campaing.service.js";
import dataLoading from "@/helpers/data/data-loading-gif.json";

export default {
  components: {
    CommonCard,
    CommondForm: () => import("@/components/commonComponents/CommondForm.vue"),
    CommondLoading: () =>
      import("@/components/commonComponents/CommondLoading.vue"),
  },
  props: {
    dialog: Boolean,
    route: String,
    idCampaing: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      request: {
        agente_id: "700",
      },
      run: false,
      canales: [],
      ramos: [],
      requiredRule: [(v) => !!v || "El campo es requerido"],
      validate: false,
      action: "POST",
      oldCampaingInfo: {},
      dataLoading: dataLoading,
      loadingModal: false,
    };
  },
  watch: {
    run(val) {
      if (val) {
        Object.assign(this.request, trimJsonMethod(this.request));
        if (this.request.negocio == "LIFO") delete this.request.negocio;
        this.validate = true;
      } else this.validate = false;
    },

    idCampaing(val) {
      if (val) this.oldCampaingInfo = getCampaingDetails(this.idCampaing);
    },
  },
  methods: {
    afterError() {
      this.$emit("closeModal");
      this.run = false;
      this.validate = false;
    },
    async getRamos() {
      this.ramos = await getRamoList();
    },
    async getCanales() {
      this.canales = await getCanalList();
    },
    async getData() {
      this.loadingModal = true
      this.oldCampaingInfo = await getCampaingDetails(this.idCampaing);
      this.request.agente_id = this.oldCampaingInfo.agente_id ?? '';
      this.request.app_id = this.oldCampaingInfo.appId ?? '';
      this.request.canal = this.oldCampaingInfo.canal ?? '';
      this.request.contacto_chat = this.oldCampaingInfo.contactoChat ?? '';
      this.request.id_templete_chat = this.oldCampaingInfo.idTemplateChat ?? '';
      this.request.negocio = this.oldCampaingInfo.negocio ?? '';
      this.request.producto = this.oldCampaingInfo.producto ?? '';
      this.request.ramo = this.oldCampaingInfo.ramo ?? '';
      this.request.time_hold = this.oldCampaingInfo.holdtime ?? '';
      this.request.medio = this.oldCampaingInfo.medio ?? '';
      this.request.servicio_embebido = this.oldCampaingInfo.servicio_embebido ?? '';
      this.request.intentos = this.oldCampaingInfo.intentos ?? '';
      this.request.servicio_chat = this.oldCampaingInfo.servicioChat ?? '';
      this.request.intentos_chat = this.oldCampaingInfo.intentosChat ?? '';
      if (!this.request.negocio) this.request.negocio = "LIFO";
      this.loadingModal = false
    },
  },
  mounted() {
    this.getRamos();
    this.getCanales();
    if (this.idCampaing) this.getData();
  },
};
</script>

<style>
.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;
}

.top-text {
  width: 369px;
  height: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4d5358;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.confirmation-text {
  /* Por favor, asegúrate de revisar cuidadosamente los cambios antes de continuar */

  width: 369px;
  height: 30px;

  /* Subtitle/S */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */
  text-align: center;

  color: #555555;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
</style>
